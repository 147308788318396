<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
        </a>
      </div>
      
    </div>
    
    <div class="nav-right col-12 pull-right right-header p-0">
      <ul class="nav-menus">
        
        <li class="profile-nav onhover-dropdown p-0">
           <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PreloaderService } from './shared/services/preloader.service';
import { CoreDataService } from './shared/services/core-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showPreloader = false;
  preloaderText = "Please wait..."
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService, private preloaderService:PreloaderService, private coreSevice:CoreDataService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }
  }
  ngOnInit(): void {
     this.coreSevice.setLoggedInUser(JSON.parse(sessionStorage.getItem("user")));
     this.coreSevice.setToken(sessionStorage.getItem("token"));
    
    this.preloaderService.startPreloder.subscribe((message:string) => setTimeout(() =>{
      this.showPreloader= true;
      this.preloaderText = message? message:"Please wait..."
    },0));

    this.preloaderService.stopPreloder.subscribe(() => setTimeout(() =>{
      this.showPreloader= false;
    },0))
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Angular2PhotoswipeService } from 'angular2_photoswipe';
import {AuthService  } from '../auth.service';
import {ApiService  } from '../../shared/services/api.service';
import { Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { PreloaderService } from 'src/app/shared/services/preloader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;

  constructor(private authService:AuthService, 
    private apiService:ApiService, 
    private fb: FormBuilder,
    private router:Router,
    private toaster: ToastrService,
    private preloader:PreloaderService) 
    
    {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }
  

  login() {
    this.preloader.showPreloader();
    this.apiService.login(this.loginForm.value['email'], this.loginForm.value['password']).subscribe((response:any) =>{
      // console.log(response);
      if(response.success){
        this.authService.setlogin(response.payload.user,response.payload.accessToken);
        sessionStorage.setItem("user", JSON.stringify(response.payload.user));
        sessionStorage.setItem("token", response.payload.accessToken);

        this.preloader.hidePreloader();
        this.router.navigate(['/all-videos']);
      }else{
        this.preloader.hidePreloader();
        this.toaster.error("Invalid Credentials");
      }
    });
  }

}

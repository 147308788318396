import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { CoreDataService } from 'src/app/shared/services/core-data.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  fullName = "";
  email = "";
  constructor(public authService: AuthService,
    private coreService:CoreDataService
    ) { }

  ngOnInit() {
    let user = this.coreService.getLoggedInUser();
    this.fullName = user.firstName + " " + user. lastName;
    this.email = user.email;
    // console.log(this.fullName + "  " + this.email)
  }


  logOut(){
    this.authService.setLogOut();
  }
}

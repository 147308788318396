import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl,renderUrl  } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }


  login(userName, password){
    let reqObj = {
      email:userName,
      password:password
    }
    return this.http.post(baseUrl + "auth/signin",reqObj);
  }

  getAllJobs(){
    return this.http.get(baseUrl + "renderJob/jobs");
  }
  getCompletedJobs(){
    return this.http.get(baseUrl + "renderJob/completed");
  }

  getAllTemplates(){
    return this.http.get(baseUrl + "renderJob/templates");
  }
  
  uploadImage(imageString){
    let reqObj = {
      image:imageString
    }
    return this.http.post(baseUrl + "renderJob/image",reqObj);
  }
  uploadAudio(audioFile){
    
    return this.http.post(baseUrl + "renderJob/audio",audioFile);
  }

  createJob(job){
    return this.http.post(baseUrl + "renderJob/createV2",job);
  }

  uploadToNextRenderer(requestObj){
    return this.http.post(renderUrl + "api/video",requestObj);
  
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoreDataService {

  constructor() { }

  private loggedInUser;
  private token;


  getLoggedInUser(){
    return this.loggedInUser;
  }

setLoggedInUser(user){
   this.loggedInUser = user;
  }

  getToken(){
    return this.token;
  }

setToken(token){
   this.token = token;
  }
}

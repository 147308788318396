import { Injectable } from '@angular/core';
import { CoreDataService } from '../shared/services/core-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private coreService:CoreDataService) { }

  setlogin(user,token){
    this.coreService.setLoggedInUser(user);
    this.coreService.setToken(token);
  }

  setLogOut(){
    this.coreService.setLoggedInUser(null);
    sessionStorage.removeItem("user");

    this.coreService.setToken(null);
    sessionStorage.removeItem("token");
  }
}

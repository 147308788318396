import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  constructor() { }


  startPreloder: EventEmitter<any> = new EventEmitter<any>();
  stopPreloder: EventEmitter<any> = new EventEmitter<any>();

  showPreloader(): void {
      this.startPreloder.emit(null);
  }

  hidePreloader(): void {
      this.stopPreloder.emit(null);
  }
}
